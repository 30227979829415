import React from "react"
import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout description="404 not found">
    <h1>404 - Page outside of fate</h1>
    <p>Getimians at it again!</p>
  </Layout>
)

export default NotFoundPage
